import React from "react";
import Home from "./Home";

function AppRouter() {
  return (
      <Home />
  );
}

export default AppRouter;
